import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {GuestGuardService} from './services/guards/guest-guard.service';
import {AuthGuardService} from './services/guards/auth-guard.service';

const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {
        path: 'home',
        loadChildren: './pages/home/home.module#HomePageModule',
    },
    {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginPageModule',
        canActivate: [GuestGuardService]
    },
    {
        path: 'club',
        loadChildren: './pages/club/club.module#ClubPageModule'
    },
    {path: 'table', loadChildren: './pages/table/table.module#TablePageModule'},
    {path: 'bracket', loadChildren: './pages/bracket/bracket.module#BracketPageModule'},
    {
        path: 'user-config', loadChildren: './pages/user-config/user-config.module#UserConfigPageModule',
        canActivate: [AuthGuardService]
    },
  { path: 'user-create', loadChildren: './pages/user-config/user-create/user-create.module#UserCreatePageModule',
      canActivate: [AuthGuardService] },
  { path: 'user-update/:id', loadChildren: './pages/user-config/user-update/user-update.module#UserUpdatePageModule',
      canActivate: [AuthGuardService] },  { path: 'rules', loadChildren: './pages/rules/rules.module#RulesPageModule' },




];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
